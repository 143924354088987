import * as React from "react"
import { Link } from "gatsby"
import Seo from "../components/seo"
import "../styles/styles.sass"
import Header from "../components/header"
import Footer from "../components/footer"

const NotFoundPage = () => {
  return (
  <main className="mercuryView noticeView">
    <Seo
      title="Phil Amour — Not Found"
    />
    
    <Header headerType="detail" />

    <div className="mercuryContent">
      <section className="noticeMessage">
        <h1 className="messageTitle">Page Not Found</h1>
        <p className="messageSubtitle">Couldn't find the page you're looking for.</p>

        <Link  to="/" className="button large primary">
          <div className="buttonTitle">Go to Home Page</div>
        </Link>
      </section>
    </div>
    
    <Footer />
  </main>
  )
}

export default NotFoundPage
